import React, {useRef, useState} from "react";
import {engine} from '../services/engine';
import Precheck from "./Precheck";

export function usePrecheck(config) {
    const [started, setStarted] = React.useState(false);
    const successHandlerRef = React.useRef(null);
    const errorHandlerRef = React.useRef(null);

    engine.setConfig(config);
    engine.init();

    const contextHolder = <div>
        {started && <div style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 100,
        }}>
            <Precheck
                onSuccess={(data) => {
                    setStarted(false);
                    successHandlerRef.current?.(data);
                    successHandlerRef.current = null;
                }}
                onFailed={(error) => {
                    setStarted(false);
                    errorHandlerRef.current?.(error);
                    errorHandlerRef.current = null;
                }}
            />
        </div>}
    </div>;

    const precheck = {
        start() {
            if(started) {
                throw new Error({
                    code: 5010,
                });
            }
            setStarted(true);
            return new Promise((resolve, reject) => {
                successHandlerRef.current = resolve;
                errorHandlerRef.current = reject;
            });
        },
        cancel() {
            errorHandlerRef.current?.(5001);
            setStarted(false);
        }
    };
    return [precheck, contextHolder];
}
