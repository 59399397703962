import * as H from "@vladmandic/human";
import {log} from "./log";
import {isMobile} from "react-device-detect";
import {userChallenge} from "./user_challenge";

class FaceDetector {
    engine;
    ready = false;

    config= {
        base_url: '',
        yaw: {
            start: -0.2,
            end: 0.2
        },
        roll: {
            start: -0.2,
            end: 0.2
        },
        blink: 0,
        pitch: {
            start: -0.1,
            end: 0.2
        },
        glasses: {
            start: 0,
            end: 0.5
        },
        mask: {
            start: 0,
            end: 0.5
        },
        hat: {
            start: 0,
            end: 0.5
        },
        blur: {
            start: 0,
            end: 0.9
        },
        position: {
            x: {
                start: 0.1,
                end: 0.7,
            },
            y: {
                start: 0.1,
                end: 0.3
            },
            h: {
                start: 0.7,
                end: 1
            },
        },
        mobile_position: {
            x: {
                start: 0.0,
                end: 0.25,
            },
            y: {
                start: 0.1,
                end: 0.4
            },
            h: {
                start: 0.45,
                end: 0.65
            },
        },
        message: {
            loading: 'Loading',
            noFaceDetected: 'No face detected',
            multipleFaceDetected: 'Multiple face detected',
            centerYourFace: 'Center your face',
            moveFaceAway: 'Move face away',
            moveFaceCloser: 'Move face closer',
            lookStraight: 'Look straight',
            eyesClosed: 'Eyes closed',
            keepStill: 'Keep still',
            maskDetected: 'Mask detected',
            hatDetected: 'Hat detected',
            glassesDetected: 'Glasses detected',
        },
        color: {
            borderGood: '#00ff00',
            borderBad: '#ff0000',
        },
        delay: {
            checkmark: 0.5,
            autoCapture: 1.0,
        }
    };
    setConfig(config) {
        this.config = config;
    }

    async init() {
        if(this.engine) {
            return;
        }
        const engine = new H.default({
                debug: false,
                modelBasePath: this.config.base_url + '/precheck/models',
                face: {
                    enabled: true,
                    detector: {
                        maxDetected: 2,
                    },
                    iris: {
                        enabled: true,
                    },
                    emotion: {
                        enabled: false,
                    },
                    description: {
                        enabled: false,
                    }
                },
                body: {enabled: false},
                hand: {enabled: false},
                object: {enabled: false},
            }
        );
        await engine.load();
        await engine.warmup();
        this.engine = engine;
        this.ready = true;
    }

    async detectFace(videoDom) {
        if(!this.ready) {
            return {
                success: false,
                message: this.config.message.loading,
            };
        }

        const engine = this.engine;
        await engine.detect(videoDom);

        const interpolated = engine.next(engine.result);

        if (interpolated.face.length === 0) {
            return {
                success: false,
                message: this.config.message.noFaceDetected,
            };
        }
        if (interpolated.face.length > 1) {
            return {
                success: false,
                message: this.config.message.multipleFaceDetected,
            };
        }
        const [x, y, w, h] = interpolated.face[0].boxRaw;
        const {yaw, pitch, roll} = interpolated.face[0].rotation.angle;


        const userChallengeResult = userChallenge.checkUserChallenge(interpolated);

        if(!userChallengeResult.shouldContinue) {
            return {
                success: userChallengeResult.success,
                message: userChallengeResult.message,
            };
        }

        log({
            name: 'x',
            value: x,
            threshold: `${this.config.position.x.start} -> ${this.config.position.x.end}`,
            isFailed: !(x > this.config.position.x.start && x < this.config.position.x.end)
        });
        log({
            name: 'y', value: y, threshold: `${this.config.position.y.start} -> ${this.config.position.y.end}`,
            isFailed: !(this.config.position.y.start && y < this.config.position.y.end)
        });
        log({
            name: 'h',
            value: h,
            threshold: `${this.config.position.h.start} - ${this.config.position.h.end}`,
            isFailed: h < (this.config.position.h.start) || h > this.config.position.h.end
        });
        log({
            name: 'pitch',
            value: pitch,
            threshold: `${this.config.pitch.start} -> ${this.config.pitch.end}`,
            isFailed: pitch < this.config.pitch.start || pitch > this.config.pitch.end
        });
        log({
            name: 'yaw',
            value: yaw,
            threshold: `${this.config.yaw.start} -> ${this.config.yaw.end}`,
            isFailed: Math.abs(yaw) > this.config.pitch.end
        });
        log({
            name: 'roll',
            value: roll,
            threshold: `${this.config.roll.start} -> ${this.config.roll.end}`,
            isFailed: Math.abs(roll) > this.config.roll.end
        });
        log({
            name: 'blink',
            value: interpolated.gesture.filter(it => it.gesture.includes('blink')).length,
            threshold: `${this.config.blink}`,
            isFailed: interpolated.gesture.filter(it => it.gesture.includes('blink')).length > this.config.blink,
        });

        if(isMobile) {
            if (h > this.config.mobile_position.h.end) {
                return {
                    success: false,
                    message: this.config.message.moveFaceAway,
                };
            }
            if (h < this.config.mobile_position.h.start) {
                return {
                    success: false,
                    message: this.config.message.moveFaceCloser,
                };
            }
            if (
                !(x > this.config.mobile_position.x.start && x < this.config.mobile_position.x.end)
            ) {
                return {
                    success: false,
                    message: `${this.config.message.centerYourFace}`,
                };
            }
            if(!(y > this.config.mobile_position.y.start && y < this.config.mobile_position.y.end)) {
                return {
                    success: false,
                    message: `${this.config.message.centerYourFace}`,
                };
            }
        } else {
            if (h > this.config.position.h.end) {
                return {
                    success: false,
                    message: this.config.message.moveFaceAway,
                };
            }
            if (h < this.config.position.h.start) {
                return {
                    success: false,
                    message: this.config.message.moveFaceCloser,
                };
            }
            if (
                !(x > this.config.position.x.start && x < this.config.position.x.end)
            ) {
                return {
                    success: false,
                    message: `${this.config.message.centerYourFace}`,
                };
            }
            if(!(y > this.config.position.y.start && y < this.config.position.y.end)) {
                return {
                    success: false,
                    message: `${this.config.message.centerYourFace}`,
                };
            }
        }
        if (pitch < this.config.pitch.start || pitch > this.config.pitch.end) {
            log('(pitch)');
            return {
                success: false,
                message: this.config.message.lookStraight,
            };
        }
        if (Math.abs(yaw) > this.config.yaw) {
            log('(yaw)');
            return {
                success: false,
                message: this.config.message.lookStraight,
            };
        }
        if (Math.abs(roll) > this.config.roll) {
            log('(roll)');
            return {
                success: false,
                message: this.config.message.lookStraight,
            };
        }
        if (interpolated.gesture.filter(it => it.gesture.includes('blink')).length > this.config.blink) {
            return {
                success: false,
                message: this.config.message.eyesClosed,
            };
        }
        return {
            success: true,
            message: this.config.message.keepStill,
        };
    }
}

export const faceDetector = new FaceDetector();
