import {validateCameraNameAndResolution} from "./nonce";
import {ERROR_CODE} from "./constant";

class CameraStandardValidation {
    // TODO: get this from backend
    whitelistCameraResolution = ['640x480'];
    blacklistCameraName = ['virtual', 'obs'];

    async validateCameraCount() {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === 'videoinput');
        console.log(videoDevices, 'videoDevices');
        if(videoDevices.length > 1) {
            throw new Error(ERROR_CODE.INVALID_CAMERA_COUNT);
        }
        if(videoDevices.length === 0) {
            throw new Error(ERROR_CODE.NO_CAMERA_FOUND);
        }
    }
    async validateCameraNameAndResolution(requestId) {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === 'videoinput');

        const cameraName = videoDevices[0].label;

        // const isInBlacklist = this.blacklistCameraName.some(it => cameraName.toLowerCase().includes(it.toLowerCase()));
        //
        // if(isInBlacklist) {
        //     throw new Error("5002");
        // }

        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        const videoTrack = stream.getVideoTracks()[0];
        const settings = videoTrack.getSettings();

        const cameraResolution = `${settings.width}x${settings.height}`;
        // const isInWhitelist = this.whitelistCameraResolution.some(it => videoResolution.toLowerCase().includes(it.toLowerCase()));

        // if(!isInWhitelist) {
        //     throw new Error("5004");
        // }

        const request = {
            cameraName,
            cameraResolution,
        };

        const validationResult = await validateCameraNameAndResolution(requestId, request);

        if(validationResult.code === "1044") {
            throw new Error(ERROR_CODE.INVALID_CAMERA_NAME);
        }
        if(validationResult.code === "1045") {
            throw new Error(ERROR_CODE.INVALID_CAMERA_RESOLUTION);
        }

        stream.getTracks().forEach(function(track) {
            track.stop();
        });
    }

    async validate(requestId) {
        await this.validateCameraCount();
        await this.validateCameraNameAndResolution(requestId);
    }
}

export const cameraStandardValidation = new CameraStandardValidation();
