import {ClassificationModel} from "@microsoft/customvision-tfjs";
import {log} from "./log";

class CapDetector {
    engine;
    config= {
        base_url: '',
        yaw: {
            start: -0.2,
            end: 0.2
        },
        roll: {
            start: -0.2,
            end: 0.2
        },
        blink: 0,
        pitch: {
            start: -0.1,
            end: 0.2
        },
        glasses: {
            start: 0,
            end: 0.5
        },
        mask: {
            start: 0,
            end: 0.5
        },
        hat: {
            start: 0,
            end: 0.5
        },
        blur: {
            start: 0,
            end: 0.9
        },
        position: {
            x: {
                start: 0.1,
                end: 0.7,
            },
            y: {
                start: 0.1,
                end: 0.3
            },
            h: {
                start: 0.7,
                end: 1
            },
        },
        mobile_position: {
            x: {
                start: 0.0,
                end: 0.25,
            },
            y: {
                start: 0.1,
                end: 0.4
            },
            h: {
                start: 0.45,
                end: 0.65
            },
        },
        message: {
            loading: 'Loading',
            noFaceDetected: 'No face detected',
            multipleFaceDetected: 'Multiple face detected',
            centerYourFace: 'Center your face',
            moveFaceAway: 'Move face away',
            moveFaceCloser: 'Move face closer',
            lookStraight: 'Look straight',
            eyesClosed: 'Eyes closed',
            keepStill: 'Keep still',
            maskDetected: 'Mask detected',
            hatDetected: 'Hat detected',
            glassesDetected: 'Glasses detected',
        },
        color: {
            borderGood: '#00ff00',
            borderBad: '#ff0000',
        },
        delay: {
            checkmark: 0.5,
            autoCapture: 1.0,
        }
    };

    setConfig(config) {
        this.config = config;
    }
    async init() {
        let model = new ClassificationModel();
        await model.loadModelAsync(this.config.base_url + '/precheck/models/cap/model.json');
        this.engine = model;
    }

    async detect(videoDom) {
        const engine = this.engine;
        if(!engine) {
            return {
                success: false,
                message: this.config.message.loading,
            };
        }
        const result = await engine.executeAsync(videoDom);

        const label = ['hat', 'negative'];

        const results = label.map((name, index) => {
            return {
                name,
                prob: result?.[0]?.[index] ?? 0,
            };
        });

        results.forEach(it => {
            log({
                name: it.name,
                value: it.prob,
                threshold: `${this.config.hat.start} -> ${this.config.hat.end}`,
            });
        });

        const hatProbability = result[0][0];

        if (hatProbability > this.config.hat.end) {
            return {
                success: false,
                message: this.config.message.hatDetected,
            };
        }
        return {
            success: true,
            message: this.config.message.keepStill,
        };
    }}

export const capDetector = new CapDetector();
