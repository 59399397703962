import {faceDetector} from "./face";
import {log} from "./log";
import {capDetector} from "./cap";
import {maskGlassDetector} from "./mask_glass";
import {userChallenge} from "./user_challenge";

const timeout = (n) => new Promise((resolve, reject) => setTimeout(resolve, n));

class Engine {
    onSuccess;
    onFailed;

    firstGoodFrameTime = 0;

    ready = false;
    starting = false;
    initializing = false;

    config = {
        features: {
            capDetector: true,
            maskGlassDetector: true,
        },
        base_url: '',
        yaw: {
            start: -0.2,
            end: 0.2
        },
        roll: {
            start: -0.2,
            end: 0.2
        },
        blink: 0,
        pitch: {
            start: -0.1,
            end: 0.2
        },
        glasses: {
            start: 0,
            end: 0.5
        },
        mask: {
            start: 0,
            end: 0.5
        },
        hat: {
            start: 0,
            end: 0.5
        },
        blur: {
            start: 0,
            end: 0.9
        },
        position: {
            x: {
                start: 0.1,
                end: 0.7,
            },
            y: {
                start: 0.1,
                end: 0.3
            },
            h: {
                start: 0.7,
                end: 1
            },
        },
        mobile_position: {
            x: {
                start: 0.0,
                end: 0.25,
            },
            y: {
                start: 0.1,
                end: 0.4
            },
            h: {
                start: 0.45,
                end: 0.65
            },
        },
        message: {
            loading: 'Loading',
            noFaceDetected: 'No face detected',
            multipleFaceDetected: 'Multiple face detected',
            centerYourFace: 'Center your face',
            moveFaceAway: 'Move face away',
            moveFaceCloser: 'Move face closer',
            lookStraight: 'Look straight',
            eyesClosed: 'Eyes closed',
            keepStill: 'Keep still',
            maskDetected: 'Mask detected',
            hatDetected: 'Hat detected',
            glassesDetected: 'Glasses detected',
        },
        color: {
            borderGood: '#00ff00',
            borderBad: '#ff0000',
        },
        delay: {
            checkmark: 0.5,
            autoCapture: 1.0,
        },
        url: {
            frid: 'https://idlive.totmtech.in',
        },
    };

    setConfig(config={}) {
        Object.assign(this.config, config);
        faceDetector.setConfig(this.config);
        capDetector.setConfig(this.config);
        maskGlassDetector.setConfig(this.config);
    }

    async init() {
        if(this.initializing) {
            return;
        }
        this.initializing = true;
        await faceDetector.init();
        console.log('Face initialized');
        if(this.config.features.capDetector) {
            await capDetector.init();
            console.log('Cap initialized');
        }
        if(this.config.features.maskGlassDetector) {
            await maskGlassDetector.init();
            console.log('Mask glass initialized');
        }
        userChallenge.reset();
        this.initializing = false;
        this.ready = true;
    }

    async startDetection(onSuccess, onFailed) {
        if(this.starting) {
            throw new Error('starting');
        }
        this.starting = true;
        while(true) {
            if(this.ready) {
                break;
            } else {
                this.init();
                await timeout(500);
            }
        }
        this.onSuccess = onSuccess;
        this.onFailed = onFailed;
        this.starting = false;
        log('startDetection');
    }

    async detect(videoDom) {
        let lastResult;
        const resultFace = await faceDetector.detectFace(videoDom);
        lastResult = resultFace;
        if(!resultFace.success) {
            this.firstGoodFrameTime = 0;
            return resultFace;
        }
        if(this.config.features.capDetector) {
            const resultCap = await capDetector.detect(videoDom);
            lastResult = resultCap;
            if(!resultCap.success) {
                this.firstGoodFrameTime = 0;
                return resultCap;
            }
        }
        if(this.config.features.maskGlassDetector) {
            const resultMaskGlass = await maskGlassDetector.detect(videoDom);
            lastResult = resultMaskGlass;
            if(!resultMaskGlass.success) {
                this.firstGoodFrameTime = 0;
                return resultMaskGlass;
            }
        }

        if(this.firstGoodFrameTime === 0) {
            this.firstGoodFrameTime = new Date().getTime();
        } else if ((new Date().getTime() - this.firstGoodFrameTime) >= (this.config.delay.autoCapture * 1000)) {
            this.internalOnSuccess(videoDom);
        }
        return lastResult;
    }

    async internalOnSuccess(videoDom) {
        if(!this.onSuccess) {
            return;
        }
        const screenshot = await this.getVideoScreenshot(videoDom);
        this.onSuccess(screenshot);
        this.onSuccess = null;
    }

    async getVideoScreenshot(videoDom) {
        const video = videoDom;

        const w = video.videoWidth * 1;
        const h = video.videoHeight * 1;
        const canvas = document.createElement('canvas');
        canvas.width = w;
        canvas.height = h;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(video, 0, 0, w, h);
        return canvas.toDataURL("image/jpeg", 0.9);
    };
}

export const engine = new Engine();

