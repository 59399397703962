import logo from './logo.svg';
import './App.css';
import {useEffect, useState} from "react";
import {usePrecheck} from "./precheck-sdk";

function App() {
  const urlConfig = "https://facelive.totmtech.in";

  const [precheck, precheckContextHolder] = usePrecheck({
    features: {
      capDetector: false,
      maskGlassDetector: false,
    },
    url: {
      frid: urlConfig,
    },
    base_url: '/sample_base/url',
    yaw: {
      start: -0.2,
      end: 0.2
    },
    roll: {
      start: -0.2,
      end: 0.2
    },
    blink: 0,
    pitch: {
      start: -0.1,
      end: 0.2
    },
    glasses: {
      start: 0,
      end: 0.9
    },
    mask: {
      start: 0,
      end: 0.9
    },
    hat: {
      start: 0,
      end: 0.9
    },
    blur: {
      start: 0,
      end: 0.9
    },
    position: {
      x: {
        start: 0.1,
        end: 0.7,
      },
      y: {
        start: 0.1,
        end: 0.3
      },
      h: {
        start: 0.7,
        end: 1
      },
    },
    mobile_position: {
      x: {
        start: 0.0,
        end: 0.25,
      },
      y: {
        start: 0.1,
        end: 0.4
      },
      h: {
        start: 0.45,
        end: 0.65
      },
    },
    message: {
      loading: 'Loading',
      noFaceDetected: 'Face not detected',
      multipleFaceDetected: 'Multiple face detected',
      centerYourFace: 'Center your face',
      moveFaceAway: 'Move face away',
      moveFaceCloser: 'Move face closer',
      lookStraight: 'Look straight',
      eyesClosed: 'Eyes closed',
      keepStill: 'Keep still',
      maskDetected: 'Mask detected',
      hatDetected: 'Hat detected',
      glassesDetected: 'Glasses detected',
    },
    color: {
      borderGood: '#00ff00',
      borderBad: '#ff0000',
    },
    delay: {
      checkmark: 0.5,
      autoCapture: 1.0,
    }
  });

  const [image, setImage] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [nik, setNik] = useState('');
  const [validationResult, setValidationResult] = useState({valid: false});

  const submitPrecheck = async (data) => {
    const validationResult = await fetch(urlConfig + '/eKYC_MW/secureVerify', {
      method: 'POST',
      body: JSON.stringify({
        "transactionId": new Date().getTime().toString(),
        "component": "MOBILE",
        "customer_Id": "ekyc_customer_1",
        "NIK": nik,
        "device_Id": "12345678912345",
        "app_Version": "1.0",
        "sdk_Version": "1.0",
        "faceThreshold": "6",
        "passiveLiveness": "true",
        "isVerifyWithImage": false,
        "encryptedData": {
          "requestId": data.requestId,
          "additionalData": data.additionalData,
          "nonce": data.nonce,
          "hash": data.hash,
          "encryptedImage": data.encryptedImage,
        }
      }),
      headers: {
        'content-type': 'application/json',
      }
    }).then(res => res.json());

    setErrorCode((validationResult.errorCode ?? 0).toString());
    setValidationResult(validationResult);
    // setErrorCode
    console.log(validationResult, 'validationResult');
  }

  const start = async () => {
    if(nik === "") {
      alert("You need to input NIK first");

      return;
    }
    setImage("");
    try {
      const data = await precheck.start();
      setImage('data:image/png;base64,' + data.plainImage);
      submitPrecheck(data);
      setErrorCode('');
      console.log(data, 'data');
    } catch (error) {
      setErrorCode(error.code);
      console.log(error, 'error');
    }
  };
  return (
    <div className="App">
      <header className="App-header">
        <div>Input NIK: <input type="text" onChange={(e) => {
          setNik(e.target.value);
        }}/></div>

        <div>Result:</div>
        <img src={image} alt=""/>
        <div>Error code: {errorCode}</div>
        <div>Validation result: {validationResult.errorMessage}</div>
        <button onClick={start}>Start Precheck</button>
      </header>
      {precheckContextHolder}
    </div>
  );
}

export default App;
