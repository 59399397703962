import {useEffect, useRef, useState} from "react";
import {engine} from "../services/engine";
import {BrowserView, MobileView, isMobile} from "react-device-detect";
import {getNonce} from "../services/nonce";
import {encrypt, hash} from "../services/rsa";
import * as CryptoJS from "crypto-js";
import {cameraStandardValidation} from "../services/camera_standard_validation";
import {userChallenge} from "../services/user_challenge";
import {encryptAES, setParameter} from "../services/aes";

function Precheck({
                      onFailed,
                      onSuccess
                  }) {
    const width = isMobile ? document.body.clientWidth : 960;
    const height = isMobile ? document.body.clientWidth * 4 / 3 : 540;
    const containerWidth = isMobile ? 0 : width;
    const containerHeight = isMobile ? 0 : height;
    const [message, setMessage] = useState('');
    const nonceRef = useRef('');
    const requestIdRef = useRef('');
    const cameraNameRef = useRef('');
    const [showCheckmark, setShowCheckmark] = useState(false);
    const streamRef = useRef();

    useEffect(() => {
        try {
            if(message === engine.config.message.keepStill) {
                borderGreen();
            } else {
                borderRed();
            }
        } catch (e) {}
    }, [message]);

    const initializing = useRef();
    const detecting = useRef(false);

    useEffect(() => {
        (async () => {
            const {nonce, iv, passphrase, requestId, challengeResponse, maxFail} = await getNonce();
            nonceRef.current = nonce;
            requestIdRef.current = requestId;
            setParameter(passphrase, iv);
            userChallenge.setUserChallenge(challengeResponse);
            userChallenge.setMaxFailure(maxFail);
            // initDom();
            await initVideo();
            engine.init();
        })();
    }, []);

    const borderRed = () => {
        const context = document.getElementById('canvas').getContext('2d');
        context.beginPath();
        if(isMobile) {
            context.arc(width / 2, height / 2, width * 0.44, 0, 2 * Math.PI, false);
        } else {
            context.arc(width / 2, height / 2, 240, 0, 2 * Math.PI, false);
        }
        context.lineWidth = 5;
        context.strokeStyle = engine.config.color.borderBad;
        context.stroke();
    };
    const borderGreen = () => {
        const context = document.getElementById('canvas').getContext('2d');
        context.beginPath();
        if(isMobile) {
            context.arc(width / 2, height / 2, width * 0.44, 0, 2 * Math.PI, false);
        } else {
            context.arc(width / 2, height / 2, 240, 0, 2 * Math.PI, false);
        }
        context.lineWidth = 5;
        context.strokeStyle = engine.config.color.borderGood;
        context.stroke();
    };

    const _onFailed = (code) => {
        console.log('onFailed', code);
        if(onFailed) {
            onFailed(code);
        }
        if(streamRef.current) {
            streamRef.current.getTracks().forEach(function(track) {
                track.stop();
            });
        }
        detecting.current = false;
    };

    const initVideo = async () => {
        if (initializing.current) {
            return;
        }
        initializing.current = true;

        const video = document.getElementById('video');

        let constraint = {
            audio: false, video: {
                facingMode: 'user',
                width: { ideal: 1280 },
                height: { ideal: 720 }
            }
        };
        if(isMobile) {
            constraint = {
                audio: false, video: {
                    facingMode: 'user',
                    width: { ideal: 960 },
                    height: { ideal: 720 }
                }
            }
        }

        try {
            await cameraStandardValidation.validate(requestIdRef.current);
            const stream = await navigator.mediaDevices.getUserMedia(constraint);
            streamRef.current = stream;
            video.onloadeddata = () => {
                engine.startDetection(async (_data) => {
                    const data = _data.split(',')[1];
                    setShowCheckmark(true);
                    const encryptedImage = encryptAES(data);
                    const encryptedAdditionalData = encryptAES(JSON.stringify({
                        cameraName: cameraNameRef.current,
                    }));
                    const response = {
                        encryptedImage: encryptedImage.ciphertext.toString(CryptoJS.enc.Base64),
                        hash: hash(requestIdRef.current, data, nonceRef.current),
                        plainImage: data,
                        additionalData: encryptedAdditionalData.ciphertext.toString(CryptoJS.enc.Base64),
                        requestId: requestIdRef.current,
                        nonce: encrypt(nonceRef.current),
                    };
                    console.log('done');
                    if(onSuccess) {
                        setTimeout(() => {
                            onSuccess(response);
                        }, engine.config.delay.checkmark);
                    } else {
                        console.log(response, 'response');
                    }
                    stream.getTracks().forEach(function(track) {
                        track.stop();
                    });
                    detecting.current = false;
                }, );
                detecting.current = true;
                detect();
            };

            video.srcObject = stream;
            video.play();
        } catch (e) {
            if(e.message === "Permission denied") {
                _onFailed(5000);
            }
            if(!isNaN(e.message)) {
                _onFailed(+e.message);
            }

            console.log(e);
        }
    };

    const detect = async () => {
        // console.log('detectLoop', detecting.current);
        const video = document.getElementById('video');
        try {
            const result = await engine.detect(video);
            if(message !== result.message) {
                setMessage(result.message);
            }
            if(detecting.current) {
                requestAnimationFrame(detect);
            }
        } catch (e) {
            _onFailed(+e.message);
            console.log(+e.message, '+e.message');
            return;
        }
    }

    return (
        <>
            <BrowserView>
                <div style={{
                    position: 'absolute',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    width: '100vw',
                }}>
                    <div id="video_container" style={{
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'center',
                        flexDirection: 'column',
                        backgroundColor: 'black',
                    }}>
                        <div style={{width: '100%', display: 'flex'}}>
                            <img src={engine.config.base_url + "/precheck/image/exit.png"}
                                 style={{
                                     height: 24,
                                     marginTop: 8,
                                     marginBottom: 8,
                                     marginLeft: 8
                                 }}
                                 onClick={() => {
                                     _onFailed(5001);
                                 }}
                            />
                        </div>
                        <div style={{position: 'relative'}}>
                            <video id="video" autoPlay muted playsInline={true} style={{
                                width: width,
                                height: height,
                                "-moz-transform": "scaleX(-1)",
                                "-o-transform": "scaleX(-1)",
                                "-webkit-transform": "scaleX(-1)",
                                "transform": "scaleX(-1)",
                                "filter": 'FlipH',
                                "-ms-filter": "FlipH"
                            }}/>

                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                position: 'absolute',
                                left: 0,
                                top: 0,
                                opacity: 0.5,
                                width: width,
                            }}>
                                <div style={{flex: 1,
                                    background: '#000'}}></div>
                                <img src={engine.config.base_url + "/precheck/image/face_cover.png"} id="face_cover"
                                     alt="face_cover"
                                     style={{
                                         height: height,
                                     }}
                                />
                                <div style={{flex: 1,
                                    background: '#000'}}></div>
                            </div>
                            <canvas id="canvas" width={width} height={height} style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                            }}/>
                            {showCheckmark && <img src="/precheck/image/checkmark.png" alt="" style={{
                                position: 'absolute',
                                width: 100,
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            }}/>}
                        </div>
                        <div style={{color: 'white', marginTop: 8, marginBottom: 8}}>{message}</div>
                    </div>
                </div>
            </BrowserView>
            <MobileView>
                <div style={{
                    position: 'absolute',
                    display: 'flex',
                    // justifyContent: 'center',
                    // alignItems: 'center',
                    height: '100svh',
                    width: '100vw',
                }}>
                    <div id="video_container" style={{
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexDirection: 'column',
                        backgroundColor: 'black',
                    }}>
                        <div style={{width: '100%'}}>
                            <img src={engine.config.base_url + "/precheck/image/exit.png"}
                                 style={{
                                     height: 24,
                                     marginTop: 8,
                                     marginBottom: 8,
                                     marginLeft: 8
                                 }}
                                 onClick={() => {
                                     _onFailed(5001);
                                 }}
                            />
                        </div>
                        <div style={{position: 'relative'}}>
                            <video id="video" autoPlay muted playsInline={true} style={{
                                width: '100vw',
                                height: 'calc((100vw) * 4 / 3)',
                                // height: height,
                                "-moz-transform": "scaleX(-1)",
                                "-o-transform": "scaleX(-1)",
                                "-webkit-transform": "scaleX(-1)",
                                "transform": "scaleX(-1)",
                                "filter": 'FlipH',
                                "-ms-filter": "FlipH"
                            }}/>

                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                position: 'absolute',
                                left: 0,
                                top: 0,
                                opacity: 0.5,
                                width: '100vw',
                            }}>
                                <div style={{flex: 1,
                                    background: '#000'}}></div>
                                <img src={engine.config.base_url + "/precheck/image/face_cover_portrait.png"} id="face_cover"
                                     alt="face_cover"
                                     style={{
                                         width: '100vw',
                                         // height: height,
                                     }}
                                />
                                <div style={{flex: 1,
                                    background: '#000'}}></div>
                            </div>
                            <canvas id="canvas" width={width} height={height} style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                            }}/>
                            {showCheckmark && <img src={engine.config.base_url + "/precheck/image/checkmark.png"} alt="" style={{
                                position: 'absolute',
                                width: 100,
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            }}/>}
                        </div>
                        <div style={{color: 'white', marginTop: 8, marginBottom: 8}}>{message}</div>
                    </div>
                </div>
            </MobileView>
        </>
    );
}

export default Precheck;
