import JSEncrypt from "jsencrypt";
import sha512 from "js-sha512";

const jsDecrypt = new JSEncrypt();
const jsEncrypt = new JSEncrypt();
jsDecrypt.setPrivateKey(`-----BEGIN RSA PRIVATE KEY-----
MIIEowIBAAKCAQEAoc7gX+VJvViB2aeET7xt6wWYQPkluAeshLWNlpzqrPdcdCPH
lOWs4ULLMpIPSTFyDk6Uge9hvQN3PfcAm1AbEPHINaWqNhyjTsVVIQ7TttSEbciI
0dv89Ld48pDpFxXo3KIO0DfzfPVBlW9kpelQ0VxmAvD/PQ5b6PkaJbNyc/JOBDTo
wRh5nab1H/5WJ3tQ7/quAecwwqgDQksm3fQTWWzCPLq0TKHLOxg9ORSM5gxWWN5i
Si7tLJ3k27XYvwycho+k0aIzHpFQDg9bsaXQQpQ/YL7hz41ZOixz/lO6B9UAq3bn
sjwPPYe2WqsV0rGR3Cbu8OKBfBN+j27T/Km0pwIDAQABAoIBABQDvXBPMdRYftNX
679bLPN9wSwERXyigl32ARoIteSIQmjhRh4uXLiOZQkP1RE2KwgHA0qhPCM/qv/A
rlQH0BlD9wJfyBKibzbepN2OcnSyi+Dyha4DuqHgkfbbIuHmpWOpLhDmYtlpkt+z
pCz7k7IZ7kpd2gyI2qiSK4BPVpBtzUXqE7C7x/ncpME4JFqJE3nbi0rZJVPXZgWQ
YsM57+nmTXopa39HG3smRZiI1TByxdDCpOv4+bFpqFwmhFKueBXoYDjAKugcosMi
BgLK/lzkmYuOwu/S9T148nuQEEMTl681cueOkXyo3Cd3j/oDCi3OFQi2KHG1xeNn
2QQdQHECgYEAt2MjHopuU0SUzqR0gx42SezONTUWbIQnrsE/DBM5uC1LjbdsljdE
CqSvch8I2g2sTR+U5UlBCgnfvhP/g1uNoAZ7HmRl287tqkz1pCDOq0HppVmODJyz
k+OmQO5ehnBiRICvvI4DBQMrDwIjYhQc/6tJ5c45b3HjO9Tqg9TgEDcCgYEA4eBk
LYF6RL0gh/keLtkpDCl2nbRHtKDhYOZ31ACw8OV6tKPotd1TLkVqXVZRTwt43XQS
4aTkzYnvD0Zx7tfruORs8aKsAGUs2VjMwMbrmmtLzTq8GVg+hgXpUD3XbXDk4xRW
JD29b59z+yndSNxoIe4ouKVAdUhoxOz1MmSR5xECgYAA8LEtZuhJ5QAeTxmJ0o3U
szIbc7o5tDO/4Z3BIhgZqaHJiDc2TfI7W7zKiP4yn/9SJedNhaKfuo07HD8jHUUM
nPvkm/8psVXmmZ5oyQJGPoBEKMFhryv8hh5EFvTFG65lS4ObI99AF1QRL6GezxuW
8/ndA8GnoJ3ZoK3THzRG4QKBgHKJJkMs9KMGhAhu+i8nTvkrzUoKsJGXQrGwK/ME
prSAqMgQlWAi6pr7+AN8UZ6TYqZoxWpGAv92ghJs1jkWpCENM3ET28QXibZsoObf
93hGEBnxPpBbftjXBof509Na+7VqCoQ2CmkfoeAqSls36FXMPjnjEdxm6zlwLqVu
+c0BAoGBAIU1eNHCQLj82VLrrLSvOZYufBuZVu3yaEpv0OGMllwWwyKLRQOg5Bh3
/zyGn3xMzHxDDBF/7BnE5kJB6DHrhpzkcrOKU2lL0bqUOXlfYi9n2U9anzyDPCuH
GH4miQLGsm638SY5cA7kSAzktYA8OJ/wcCPMtnBw8y1nMmW62cKK
-----END RSA PRIVATE KEY-----`);
jsEncrypt.setPublicKey(`-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAppoO5s0uzjumZYuGRSEV
Gf7I3Ob3r0A0vtl8MPjG4f9hWM7264EUjBEFZnQLKBjK5YNMZAsAvPmCbUdYFJiD
4C7dcX5skl3Vd8jB6MBj7Ab8aY7k3fIYpRO0Tx5ccVKZiKj1gAeP2bj6ZozdZTCf
Ag0KxaG0z0kpe/gtoUiWryFhX8RMwORL7uHEct5inYGp0KW8JlRoxHCEvNScPAmN
uF5jUD7BsU2z4UCJO+vglsLnrOgP4OatM2uFnERx1KNnIf8vTv8hThbBK+Nb7b0i
vg4eERCwq+MCJ2e4GzYskLapSZxyh+pYN+TrWSLAL8VDhyckqvqqCBx9J3HKQgDd
0QIDAQAB
-----END PUBLIC KEY-----`);

export function encrypt(str) {
    return jsEncrypt.encrypt(str);
}
export function decrypt(str) {
    return jsDecrypt.decrypt(str);
}
export function hash(requestId, plainImage, nonce) {
    return sha512(requestId + plainImage + nonce);
}
