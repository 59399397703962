import {ERROR_CODE} from "./constant";

function getRandomElementsFromArray(array, numElements) {
    // Make sure the requested number of elements is not greater than the array length
    numElements = Math.min(numElements, array.length);

    // Create a copy of the array to avoid modifying the original array
    const newArray = array.slice();

    // Shuffle the array using the Fisher-Yates algorithm
    for (let i = newArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }

    // Return the first 'numElements' elements from the shuffled array
    return newArray.slice(0, numElements);
}


class UserChallenge {
    // TODO: get this from backend
    availableChallenge = ['turn_left', 'turn_right', 'open_mouth', 'close_mouth', 'look_up', 'look_down', 'close_both_eyes', 'close_left_eyes', 'close_right_eyes', 'open_both_eyes'];
    // challengeList = getRandomElementsFromArray(this.availableChallenge, 3);
    challengeList = ['turn_left', 'open_mouth', 'turn_right'];
    maxFailure = 1;
    timeout = 10000;
    holdPositionDuration = 500;

    // waiting, active, success, failed
    currentState = 'waiting';
    currentStep = 0;
    currentChallenge = '';
    isInHold = false;

    startHoldTime = 0;
    startCurrentStepTime = 0;
    currentTimeout = 0;

    successCount = 0;
    failedCount = 0;

    messageMapping = {
        turn_left: 'Turn your face to left and hold still',
        turn_right: 'Turn your face to right and hold still',
        open_mouth: 'Open your mouth and hold still',
        close_mouth: 'Close your mouth and hold still',
        look_up: 'Look up and hold still',
        look_down: 'Look down and hold still',
        close_both_eyes: 'Close both of your eyes and hold still',
        close_left_eyes: 'Close your left eyes and hold still',
        close_right_eyes: 'Close your right eyes and hold still',
        open_both_eyes: 'Open both of your eyes and hold still',
        hold_still: 'Hold still',
    };

    setUserChallenge(userChallenge) {
        this.challengeList = userChallenge;
    }

    setMaxFailure(maxFail) {
        this.maxFailure = maxFail;
    }

    onDone() {
        if(this.failedCount > this.maxFailure) {
            this.currentState = 'failed';
        } else {
            this.currentState = 'success';
        }
    }

    goToNextChallenge(isSuccess) {
        if(this.currentTimeout) {
            clearTimeout(this.currentTimeout);
            this.currentTimeout = 0;
        }

        if(this.currentStep >= 0) {
            if(isSuccess) {
                this.successCount += 1;
            } else {
                this.failedCount += 1;
            }
        }
        this.currentStep = this.currentStep + 1;
        if(!this.challengeList[this.currentStep]) {
            this.onDone();
            return;
        }
        this.currentChallenge = this.challengeList[this.currentStep];
        this.startHoldTime = 0;

        this.currentTimeout = setTimeout(() => {
            this.goToNextChallenge(false);
        }, this.timeout);
    }

    matchPose(interpolated) {
        console.log(this.currentChallenge, 'currentChallenge');
        let isMatchCurrentPose = false;
        const faceGesture = interpolated.gesture.filter(it => it.face === 0);
        if(this.currentChallenge === 'turn_left') {
            isMatchCurrentPose = !!faceGesture.find(it => it.gesture === "facing left");
        } else if(this.currentChallenge === 'turn_right') {
            isMatchCurrentPose = !!faceGesture.find(it => it.gesture === "facing right");
        } else if(this.currentChallenge === 'look_up') {
            isMatchCurrentPose = !!faceGesture.find(it => it.gesture === "head up");
        } else if(this.currentChallenge === 'look_down') {
            isMatchCurrentPose = !!faceGesture.find(it => it.gesture === "head down");
        } else if(this.currentChallenge === 'close_both_eyes') {
            const isLeftEyeClosed = !!faceGesture.find(it => it.gesture === "blink left eye");
            const isRightEyeClosed = !!faceGesture.find(it => it.gesture === "blink right eye");
            isMatchCurrentPose = isLeftEyeClosed && isRightEyeClosed;
        } else if(this.currentChallenge === 'close_left_eyes') {
            isMatchCurrentPose = !!faceGesture.find(it => it.gesture === "blink left eye");
        } else if(this.currentChallenge === 'close_right_eyes') {
            isMatchCurrentPose = !!faceGesture.find(it => it.gesture === "blink right eye");
        } else if(this.currentChallenge === 'open_both_eyes') {
            const isLeftEyeClosed = !!faceGesture.find(it => it.gesture === "blink left eye");
            const isRightEyeClosed = !!faceGesture.find(it => it.gesture === "blink right eye");
            isMatchCurrentPose = !isLeftEyeClosed && !isRightEyeClosed;
        } else if(this.currentChallenge === 'open_mouth') {
            const regex = /mouth (\d+)% open/;
            const mouthGesture = faceGesture.find(it => it.gesture.includes('mouth'));
            if (mouthGesture) {
                const match = mouthGesture.gesture.match(regex);

                if (match) {
                    const value = match[1];
                    if ((+value) > 50) {
                        isMatchCurrentPose = true;
                    }
                }
            }
        } else if(this.currentChallenge === 'close_mouth') {
            isMatchCurrentPose = true;
            const regex = /mouth (\d+)% open/;
            const mouthGesture = faceGesture.find(it => it.gesture.includes('mouth'));
            if (mouthGesture) {
                const match = mouthGesture.gesture.match(regex);

                if (match) {
                    const value = match[1];
                    if ((+value) <= 50) {
                        isMatchCurrentPose = false;
                    }
                }
            }
        }
        return isMatchCurrentPose;
    }

    checkUserChallenge(interpolated) {
        if(this.currentState === 'failed') {
            throw new Error(ERROR_CODE.FAILED_USER_CHALLENGE);
        }
        if(this.currentState === 'success') {
            return {
                shouldContinue: true,
                success: true,
                message: '',
            };
        }
        // start process
        if(this.currentState === 'waiting') {
            this.currentStep = -1;
            this.successCount = 0;
            this.failedCount = 0;
            this.currentState = 'active';
            this.goToNextChallenge();
        }

        const currentTime = new Date().getTime();
        const isMatchCurrentPose = this.matchPose(interpolated);

        if(!isMatchCurrentPose) {
            this.startHoldTime = 0;
            return {
                shouldContinue: false,
                success: false,
                message: this.messageMapping[this.currentChallenge],
            };
        }

        if(this.startHoldTime) {
            if((currentTime - this.startHoldTime) > this.holdPositionDuration) {
                this.goToNextChallenge(true);

                return {
                    shouldContinue: false,
                    success: true,
                    message: this.messageMapping.hold_still,
                };
            }

            return {
                shouldContinue: false,
                success: true,
                message: this.messageMapping.hold_still,
            };
        } else {
            this.startHoldTime = currentTime;
            return {
                shouldContinue: false,
                success: true,
                message: this.messageMapping.hold_still,
            };
        }

    }

    reset() {
        this.currentState = 'waiting';
        this.currentStep = 0;
        this.currentChallenge = '';
        this.isInHold = false;

        this.startHoldTime = 0;
        this.startCurrentStepTime = 0;
        this.currentTimeout = 0;

        this.successCount = 0;
        this.failedCount = 0;
    }
}

export const userChallenge = new UserChallenge();
