import * as CryptoJS from "crypto-js";


let passphrase = '';
let iv = '';

export function setParameter(_passphrase, _iv) {
    passphrase = _passphrase;
    iv = _iv;
}

export function encryptAES(data) {
    const keyByte = CryptoJS.enc.Hex.parse(passphrase);
    const ivByte = CryptoJS.enc.Hex.parse(iv);
    const encrypted = CryptoJS.AES.encrypt(data, keyByte, {
        iv: ivByte,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted;
}
