import * as uuid from 'uuid';
import {engine} from "./engine";
import {decrypt, encrypt} from "./rsa";
import {encryptAES} from "./aes";

const challangeNameMap = {
    blink_right_eye: 'close_right_eyes',
    blink_left_eye: 'close_left_eyes',
}

export async function getNonce() {
    const requestId = uuid.v4();

    const url = engine.config.url.frid + '/eKYC_MW/getNonce';

    const result = await fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify({
            "transactionId": (new Date().getTime() + Math.random() * 100).toFixed(0),
            "customer_Id": "ekyc_customer_1",
            "component": "MOBILE",
            "device_Id": "12345678912345",
            "app_Version": "1.0",
            "sdk_Version": "1.0",
            "requestId": requestId,
        })
    }).then(it => it.json());

    const challengeResponse = result.challenges.map(it => challangeNameMap[it.type] ?? it.type);
    const maxFail = result.max_failure;

    const nonceResponse = decrypt(result.nonce);
    const [nonce, iv, passphrase] = nonceResponse.split(';');

    return {
        requestId,
        nonce,
        iv,
        passphrase,
        challengeResponse,
        maxFail
    }
}


export async function validateCameraNameAndResolution(requestId, {cameraName, cameraResolution}) {
    const url = engine.config.url.frid + '/eKYC_MW/CameraValidations';

    const result = await fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify({
            "transactionId": new Date().getTime().toString(),
            "deviceName":cameraName,
            "camResolution": cameraResolution
        })
    }).then(it => it.json());

    return {
        code: result.errorCode,
    }
}
